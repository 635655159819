/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import Vuex from 'vuex';
import {
  createChallenge,
  createCompany,
  createIndividual,
  createOutcome,
  createTechArea,
  deleteChallenge,
  deleteCompany,
  deleteIndividual,
  deleteOutcome,
  deleteTechArea,
  getConnectionStatuses,
  createConnectionStatus,
  updateConnectionStatus,
  deleteConnectionStatus,
  getCompanyConnectionsById,
  getChallenges,
  getCompanies,
  getCompanyTypes,
  createCompanyType,
  updateCompanyType,
  deleteCompanyType,
  getCountries,
  getGdprOptions,
  getIndividualByEmail,
  getIndividuals,
  getOutcomes,
  getTechAreas,
  getEvents,
  createEvent,
  deleteEvent,
  login,
  loginWithToken,
  postExistIndividualByEmailBulk,
  updateIndividual,
  updateEvent,
  updateCompany,
  updateOutcome,
  updateChallenge,
  updateTechArea,
  getUsers,
  deleteUser,
  createUser,
  updateUser,
  getCompanyById,
  getFunders,
  getFundingTypes,
  createFundingType,
  updateFundingType,
  deleteFundingType,
  getFundingTicketSizes,
  getFundingStages,
  getFundingRoles,
  deleteFundingRole,
  createFundingRole,
  updateFundingRole,
  deleteGdprOption,
  createGdprOption,
  updateGdprOption,
  getDashboardData,
  getEventRegistrationByIndividual,
  registerIndividualToEvent,
  deleteEventRegistration,
  getIndividualByCompanyId,
  getMultiMatch,
  getBusinessModels,
  createBusinessModel,
  updateBusinessModel,
  deleteBusinessModel,
  getIndustries,
  createIndustry,
  updateIndustry,
  deleteIndustry,
  getTags,
  createTag,
  updateTag,
  deleteTag,
  getCompanyConnections,
  deleteConnection,
  createCompanyConnection,
  updateCompanyConnection,
  getCompanyNames,
} from '@/helpers/api';
import router from '@/router';
import toast from '@/helpers/toast';
import { sort } from '@/helpers/str';
import { REGIONS } from '@/helpers/constants';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    users: [],
    events: [],
    eventTypes: [
      {
        id: 0,
        name: 'Physical',
      },
      {
        id: 1,
        name: 'Virtual',
      },
      {
        id: 2,
        name: 'Hybrid',
      },
      {
        id: 3,
        name: 'Other',
      },
    ],
    countries: {},
    gdprOptions: [],
    individuals: [],
    outcomes: [],
    challenges: [],
    techAreas: [],
    connectionStatuses: [],
    companies: [],
    companyTypes: [],
    funders: [],
    fundingTypes: [],
    fundingStages: [],
    fundingRoles: [],
    fundingTicketSizes: [],
    businessModels: [],
    industries: [],
    tags: [],
    regions: REGIONS,
    searchFilterData: {},
    searchTableOptions: {},
    formDirty: false,
  },
  mutations: {
    SET_USER(state, payload) {
      if (payload) {
        localStorage.setItem('access_token', payload.accessToken);
      }
      Vue.set(state, 'user', payload);
    },
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_EVENTS(state, payload) {
      state.events = payload;
    },
    SET_COUNTRIES(state, payload) {
      payload.forEach((country) => {
        Vue.set(state.countries, country.code, country.name);
      });
    },
    SET_GDPR_OPTIONS(state, payload) {
      state.gdprOptions = payload;
    },
    SET_INDIVIDUALS(state, payload) {
      state.individuals = payload;
    },
    SET_OUTCOMES(state, payload) {
      state.outcomes = sort(payload);
    },
    SET_CHALLENGES(state, payload) {
      Vue.set(state, 'challenges', sort(payload));
    },
    SET_TECH_AREAS(state, payload) {
      state.techAreas = sort(payload);
    },
    SET_CONNECTION_STATUSES(state, payload) {
      state.connectionStatuses = sort(payload);
    },
    SET_BUSINESS_MODELS(state, payload) {
      state.businessModels = sort(payload);
    },
    SET_INDUSTRIES(state, payload) {
      state.industries = sort(payload);
    },
    SET_TAGS(state, payload) {
      state.tags = sort(payload);
    },
    SET_REGIONS(state, payload) {
      state.regions = sort(payload);
    },
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_FUNDERS(state, payload) {
      state.funders = payload;
    },
    SET_FUNDING_TYPES(state, payload) {
      state.fundingTypes = sort(payload);
    },
    SET_FUNDING_TICKET_SIZES(state, payload) {
      state.fundingTicketSizes = sort(payload);
    },
    SET_FUNDING_STAGES(state, payload) {
      state.fundingStages = payload;
    },
    SET_FUNDING_ROLES(state, payload) {
      state.fundingRoles = payload;
    },
    SET_COMPANY_TYPES(state, payload) {
      payload = payload.sort((a, b) => a.id - b.id);
      const index = payload.findIndex((i) => i.name === 'Other');
      payload.push(payload.splice(index, 1)[0]);

      Vue.set(state, 'companyTypes', payload);
      // state.companyTypes = payload;
    },
    SET_SEARCH_FILTER_DATA(state, payload) {
      Vue.set(state, 'searchFilterData', payload);
    },
    CLEAR_SEARCH_FILTER_DATA(state) {
      Vue.set(state, 'searchFilterData', {});
    },
    SET_SEARCH_TABLE_OPTIONS(state, payload) {
      Vue.set(state, 'searchTableOptions', payload);
    },
    CLEAR_SEARCH_TABLE_OPTIONS(state) {
      Vue.set(state, 'searchTableOptions', {});
    },
    SET_FORM_DIRTY(state, payload) {
      state.formDirty = payload;
    },
  },
  actions: {
    async login({ commit }, payload = {}) {
      const { email, password } = payload;
      try {
        const response = await login({ email, password });
        if (response.state) {
          commit('SET_USER', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async loginWithToken({ commit }, payload) {
      try {
        const response = await loginWithToken(payload);
        if (response.state) {
          commit('SET_USER', response.data);
          return response.data;
        } else {
          return Promise.reject();
        }
      } catch (e) {
        console.error(e);
      }
    },
    logout({ commit }) {
      localStorage.removeItem('access_token');
      commit('SET_USER', null);
      router.push('/login');
    },
    async fetchEvents({ commit }) {
      try {
        const response = await getEvents();
        if (response.state) {
          commit('SET_EVENTS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createEvent({ dispatch }, payload) {
      try {
        const response = await createEvent(payload);
        if (response.state) {
          dispatch('fetchEvents');
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateEvent({ dispatch }, payload) {
      try {
        const response = await updateEvent(payload.id, payload);
        if (response.state) {
          dispatch('fetchEvents');
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteEvent({ dispatch }, payload) {
      try {
        const response = await deleteEvent(payload);
        if (response.state) {
          return dispatch('fetchEvents');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchIndividuals({ commit }, payload = {}) {
      const { pageSize, page, filters } = payload;
      try {
        const response = await getIndividuals({ pageSize, page }, filters);
        if (response.state) {
          commit('SET_INDIVIDUALS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchIndividualByEmail({}, payload) {
      try {
        const response = await getIndividualByEmail(payload);

        if (response.state) {
          return response?.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchIndividualsByCompanyId({}, payload) {
      try {
        const response = await getIndividualByCompanyId(payload);
        if (response.state) {
          return response?.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async checkEmailExistBulk({}, payload) {
      try {
        const response = await postExistIndividualByEmailBulk(payload);

        if (response.state) {
          return response?.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCountries({ commit }) {
      try {
        const response = await getCountries();
        if (response.state) {
          commit('SET_COUNTRIES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchGdprOptions({ commit }) {
      try {
        const response = await getGdprOptions();
        if (response.state) {
          commit('SET_GDPR_OPTIONS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteGdprOption({ dispatch }, payload) {
      try {
        const response = await deleteGdprOption(payload);
        if (response.state) {
          return dispatch('fetchGdprOptions');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createGdprOption({ dispatch }, payload) {
      try {
        const response = await createGdprOption(payload);
        if (response.state) {
          dispatch('fetchGdprOptions');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateGdprOption({ dispatch }, payload) {
      try {
        const response = await updateGdprOption(payload.id, payload);
        if (response.state) {
          dispatch('fetchGdprOptions');
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createIndividual({ dispatch, state }, payload) {
      try {
        const response = await createIndividual(payload);
        if (response.state) {
          dispatch('fetchIndividuals', { filters: state.searchFilterData });
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateIndividual({ dispatch, state }, payload) {
      try {
        const response = await updateIndividual(payload.id, payload);
        if (response.state) {
          dispatch('fetchIndividuals', { filters: state.searchFilterData });
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteIndividual({ dispatch, state }, payload) {
      try {
        const response = await deleteIndividual(payload);
        if (response.state) {
          return dispatch('fetchIndividuals', { filters: state.searchFilterData });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchOutcomes({ commit }) {
      try {
        const response = await getOutcomes();
        if (response.state) {
          commit('SET_OUTCOMES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createOutcome({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createOutcome({ name });
        if (response.state) {
          dispatch('fetchOutcomes');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateOutcome({ dispatch }, payload) {
      try {
        const response = await updateOutcome(payload.id, payload);
        if (response.state) {
          dispatch('fetchOutcomes');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteOutcome({ dispatch }, payload) {
      try {
        const response = await deleteOutcome(payload);
        if (response.state) {
          return dispatch('fetchOutcomes');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchChallenges({ commit }) {
      try {
        const response = await getChallenges();
        if (response.state) {
          commit('SET_CHALLENGES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createChallenge({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createChallenge({ name });
        if (response.state) {
          dispatch('fetchChallenges');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateChallenge({ dispatch }, payload) {
      try {
        const response = await updateChallenge(payload.id, payload);
        if (response.state) {
          dispatch('fetchChallenges');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteChallenge({ dispatch }, payload) {
      try {
        const response = await deleteChallenge(payload);
        if (response.state) {
          return dispatch('fetchChallenges');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchTechAreas({ commit }) {
      try {
        const response = await getTechAreas();
        if (response.state) {
          commit('SET_TECH_AREAS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createTechArea({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createTechArea({ name });
        if (response.state) {
          dispatch('fetchTechAreas');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateTechArea({ dispatch }, payload) {
      try {
        const response = await updateTechArea(payload.id, payload);
        if (response.state) {
          dispatch('fetchTechAreas');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteTechArea({ dispatch }, payload) {
      try {
        const response = await deleteTechArea(payload);
        if (response.state) {
          return dispatch('fetchTechAreas');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchBusinessModels({ commit }) {
      try {
        const response = await getBusinessModels();
        if (response.state) {
          commit('SET_BUSINESS_MODELS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createBusinessModel({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createBusinessModel({ name });
        if (response.state) {
          dispatch('fetchBusinessModels');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateBusinessModel({ dispatch }, payload) {
      try {
        const response = await updateBusinessModel(payload.id, payload);
        if (response.state) {
          dispatch('fetchBusinessModels');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteBusinessModel({ dispatch }, payload) {
      try {
        const response = await deleteBusinessModel(payload);
        if (response.state) {
          return dispatch('fetchBusinessModels');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchIndustries({ commit }) {
      try {
        const response = await getIndustries();
        if (response.state) {
          commit('SET_INDUSTRIES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createIndustry({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createIndustry({ name });
        if (response.state) {
          dispatch('fetchIndustries');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateIndustry({ dispatch }, payload) {
      try {
        const response = await updateIndustry(payload.id, payload);
        if (response.state) {
          dispatch('fetchIndustries');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteIndustry({ dispatch }, payload) {
      try {
        const response = await deleteIndustry(payload);
        if (response.state) {
          return dispatch('fetchIndustries');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFunders({ commit }, payload = {}) {
      const { pageSize, page, filters } = payload;
      try {
        const response = await getFunders({ pageSize, page }, filters);
        if (response.state) {
          commit('SET_FUNDERS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchTags({ commit }) {
      try {
        const response = await getTags();
        if (response.state) {
          commit('SET_TAGS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createTag({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createTag({ name });
        if (response.state) {
          dispatch('fetchTags');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateTag({ dispatch }, payload) {
      try {
        const response = await updateTag(payload.id, payload);
        if (response.state) {
          dispatch('fetchTags');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteTag({ dispatch }, payload) {
      try {
        const response = await deleteTag(payload);
        if (response.state) {
          return dispatch('fetchTags');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFundingTypes({ commit }) {
      try {
        const response = await getFundingTypes();
        if (response.state) {
          commit('SET_FUNDING_TYPES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createFundingType({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createFundingType({ name });
        if (response.state) {
          dispatch('fetchFundingTypes');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateFundingType({ dispatch }, payload) {
      try {
        const response = await updateFundingType(payload.id, payload);
        if (response.state) {
          dispatch('fetchFundingTypes');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteFundingType({ dispatch }, payload) {
      try {
        const response = await deleteFundingType(payload);
        if (response.state) {
          dispatch('fetchFundingTypes');
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFundingTicketSizes({ commit }) {
      try {
        const response = await getFundingTicketSizes();
        if (response.state) {
          commit('SET_FUNDING_TICKET_SIZES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFundingStages({ commit }) {
      try {
        const response = await getFundingStages();
        if (response.state) {
          commit('SET_FUNDING_STAGES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFundingRoles({ commit }) {
      try {
        const response = await getFundingRoles();
        if (response.state) {
          commit('SET_FUNDING_ROLES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteFundingRole({ dispatch }, payload) {
      try {
        const response = await deleteFundingRole(payload);
        if (response.state) {
          dispatch('fetchFundingRoles');
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createFundingRole({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createFundingRole({ name });
        if (response.state) {
          dispatch('fetchFundingRoles');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateFundingRole({ dispatch }, payload) {
      try {
        const response = await updateFundingRole(payload.id, payload);
        if (response.state) {
          dispatch('fetchFundingRoles');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCompanies({ commit }, payload = {}) {
      const { pageSize, page, filters, updateState = true } = payload;
      try {
        const response = await getCompanies({ pageSize, page }, filters);
        if (response.state) {
          if (updateState) {
            commit('SET_COMPANIES', response.data);
          }
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCompanyNames() {
      try {
        const response = await getCompanyNames();
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCompanyById({}, payload) {
      try {
        const response = await getCompanyById(payload);
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createCompany({ state, dispatch }, payload) {
      try {
        const response = await createCompany(payload);
        if (response.state) {
          dispatch('fetchCompanies', { filters: state.searchFilterData });
          dispatch('fetchFunders', { filters: state.searchFilterData });
          return response.data;
        } else {
          toast.error('Failed to create company!');
        }
      } catch (e) {
        toast.error('Failed to create company!');
        console.error(e);
      }
    },
    async updateCompany({ state, dispatch }, payload) {
      try {
        const response = await updateCompany(payload.id, payload);
        if (response.state) {
          dispatch('fetchCompanies', { filters: state.searchFilterData });
          dispatch('fetchFunders', { filters: state.searchFilterData });
          return response.data;
        } else {
          toast.error('Failed to update company!');
        }
      } catch (e) {
        toast.error('Failed to update company!');
        console.error(e);
      }
    },
    async deleteCompany({ dispatch, state }, payload) {
      try {
        const response = await deleteCompany(payload);
        if (response.state) {
          dispatch('fetchFunders', { filters: state.searchFilterData });
          return dispatch('fetchCompanies', { filters: state.searchFilterData });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCompanyTypes({ commit }) {
      try {
        const response = await getCompanyTypes();
        if (response.state) {
          commit('SET_COMPANY_TYPES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createCompanyType({ dispatch }, payload) {
      const { name, parent } = payload;
      try {
        const response = await createCompanyType({ name, parent });
        if (response.state) {
          dispatch('fetchCompanyTypes');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateCompanyType({ dispatch }, payload) {
      try {
        const response = await updateCompanyType(payload.id, payload);
        if (response.state) {
          dispatch('fetchCompanyTypes');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteCompanyType({ dispatch }, payload) {
      try {
        const response = await deleteCompanyType(payload);
        if (response.state) {
          return dispatch('fetchCompanyTypes');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchDashboardData() {
      try {
        const response = await getDashboardData();
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchUsers({ commit }) {
      try {
        const response = await getUsers();
        if (response.state) {
          commit('SET_USERS', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteUser({ dispatch }, payload) {
      try {
        const response = await deleteUser(payload);
        if (response.state) {
          return dispatch('fetchUsers');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createUser({ dispatch }, payload) {
      try {
        const response = await createUser(payload);
        if (response.state) {
          dispatch('fetchUsers');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateUser({ dispatch }, payload) {
      try {
        const response = await updateUser(payload.id, payload);
        if (response.state) {
          dispatch('fetchUsers');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchIndividualEventRegistrations({}, payload) {
      try {
        const response = await getEventRegistrationByIndividual(payload);
        if (response.state) {
          return response.data;
        }
        return null;
      } catch (e) {
        console.error(e);
      }
    },
    async registerIndividualToEvent({}, payload) {
      try {
        const response = await registerIndividualToEvent(payload);
        if (response.state) {
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteEventRegistration({}, payload) {
      try {
        const response = await deleteEventRegistration(payload);
        if (response.state) {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchMultiMatch({}, payload = {}) {
      const { pageSize, page, filters, params } = payload;
      try {
        const response = await getMultiMatch({ pageSize, page, params }, filters);
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchConnectionStatuses({ commit }) {
      try {
        const response = await getConnectionStatuses();
        if (response.state) {
          commit('SET_CONNECTION_STATUSES', response.data);
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createConnectionStatus({ dispatch }, payload) {
      const { name } = payload;
      try {
        const response = await createConnectionStatus({ name });
        if (response.state) {
          dispatch('fetchConnectionStatuses');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateConnectionStatus({ dispatch }, payload) {
      try {
        const response = await updateConnectionStatus(payload.id, payload);
        if (response.state) {
          dispatch('fetchConnectionStatuses');
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteConnectionStatus({ dispatch }, payload) {
      try {
        const response = await deleteConnectionStatus(payload);
        if (response.state) {
          return dispatch('fetchConnectionStatuses');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCompanyConnectionsById({}, payload) {
      try {
        const response = await getCompanyConnectionsById(payload);
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchConnections({}) {
      try {
        const response = await getCompanyConnections();
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteConnection({}, payload = {}) {
      try {
        const { company1, company2, eventId } = payload;
        const response = await deleteConnection(company1, company2, eventId);
        if (response.state) {
          return response.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createCompanyConnection({}, payload) {
      const { company1Id, company2Id, eventId, statusId, nextSteps } = payload;
      try {
        const response = await createCompanyConnection({ company1Id, company2Id, eventId, statusId, nextSteps });
        if (response.state) {
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateCompanyConnection({}, payload) {
      const { oldValue, newValue } = payload;
      try {
        const response = await updateCompanyConnection({ oldValue, newValue });
        if (response.state) {
          return response.data;
        } else {
          if (response.status === 409) {
            toast.error('This record look like a duplicate.');
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    getCountriesArray(state) {
      return Object.entries(state.countries).map((item) => ({
        code: item[0],
        name: item[1],
      }));
    },
    getFunderCompanyTypeId(state) {
      return state.companyTypes.find((f) => f.name === 'Funder')?.id;
    },
    getStartupCompanyTypeId(state) {
      return state.companyTypes.find((f) => f.name === 'Startup & Scale-up')?.id;
    },
    getStartupStages(state) {
      const startupCompanyType = state.companyTypes.find((c) => c.name === 'Startup & Scale-up');
      return state.companyTypes.filter((c) => c?.parent === startupCompanyType?.id);
    },
    getCompanySubTypes(state) {
      return state.companyTypes.filter((c) => !!c.parent);
    },
    getTopLevelCompanyTypes(state) {
      return state.companyTypes.filter((c) => !c.parent);
    },
  },
});
