import { del, get, post, put } from './fetch';
async function ResponseAdapter(response, autoRedirectLogin = true) {
  switch (response.status) {
    case 401:
      localStorage.removeItem('access_token');
      console.error(401); // eslint-disable-line
      if (autoRedirectLogin) {
        window.location = `${window.location.origin}/login?redirect=${window.location.origin}`;
      }
      break;
    case 200:
    case 201:
      return {
        state: true,
        status: response.status,
        data: await response.json(),
      };
    case 204:
      return {
        state: true,
        status: response.status,
      };
    case 400:
      return {
        state: false,
        status: response.status,
        data: await response.json(),
      };
    case 403:
      try {
        let currentUser = response.headers.get('Current-User');
        if (currentUser) {
          currentUser = JSON.parse(currentUser);
        }
        const parsedResponse = await response.json();
        return {
          state: false,
          status: response.status,
          data: parsedResponse,
          currentUser,
        };
      } catch (e) {
        return {
          state: false,
          status: response.status,
          message: e.message,
        };
      }
    case 409:
      return {
        state: false,
        status: response.status,
      };
    case 500:
      return {
        state: false,
        status: response.status,
      };
    default:
      break;
  }

  return {
    state: false,
    status: response.status,
    message: 'UNKNOWN_DATA',
  };
}
function toQueryString(paramsObject) {
  return Object.keys(paramsObject)
    .filter((key) => paramsObject[key])
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join('&');
}

export function stringify(params) {
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'string' && params[key].includes('&')) {
      params[key] = params[key].replace('&', '%26');
    }
  });
  return JSON.stringify(params);
}

// Authentication
export async function loginWithToken(token) {
  const response = await post('/loginWithToken/', undefined, { authorization: `Bearer ${token}` });
  return ResponseAdapter(response, false);
}

export async function login(data) {
  const response = await post('/login/', data);
  return ResponseAdapter(response, false);
}

// Dashboard
export async function getDashboardData() {
  const response = await get('/dashboard/');
  return ResponseAdapter(response);
}

export async function getIndividuals(options = {}, filters = {}) {
  const { pageSize, page } = options;
  const response = await get(`/individual/?filter=${stringify(filters)}&${toQueryString({ size: pageSize, page })}`);
  return ResponseAdapter(response);
}
export async function createIndividual(data) {
  const response = await post('/individual/', data);
  return ResponseAdapter(response);
}
export async function updateIndividual(id, data) {
  const response = await put(`/individual/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteIndividual(id) {
  const response = await del(`/individual/${id}`);
  return ResponseAdapter(response);
}
export async function getIndividualByEmail(email) {
  const response = await post('/individual/get-by-email/', { email });
  return ResponseAdapter(response);
}
export async function getIndividualByCompanyId(id) {
  const response = await get(`/individual/get-by-company-id/${id}`);
  return ResponseAdapter(response);
}
export async function postExistIndividualByEmailBulk(emails) {
  const response = await post('/individual/check-by-emails/', { emails });
  return ResponseAdapter(response);
}
// Admin Outcomes
export async function getOutcomes() {
  const response = await get('/admin/outcome/');
  return ResponseAdapter(response);
}
export async function createOutcome(data) {
  const response = await post('/admin/outcome/', data);
  return ResponseAdapter(response);
}
export async function updateOutcome(id, data) {
  const response = await put(`/admin/outcome/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteOutcome(id) {
  const response = await del(`/admin/outcome/${id}`);
  return ResponseAdapter(response);
}
// Admin Challenges
export async function getChallenges() {
  const response = await get('/admin/challenge/');
  return ResponseAdapter(response);
}
export async function createChallenge(data) {
  const response = await post('/admin/challenge/', data);
  return ResponseAdapter(response);
}
export async function updateChallenge(id, data) {
  const response = await put(`/admin/challenge/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteChallenge(id) {
  const response = await del(`/admin/challenge/${id}`);
  return ResponseAdapter(response);
}
// Admin Tech Areas
export async function getTechAreas() {
  const response = await get('/admin/tech-area/');
  return ResponseAdapter(response);
}
export async function createTechArea(data) {
  const response = await post('/admin/tech-area/', data);
  return ResponseAdapter(response);
}
export async function updateTechArea(id, data) {
  const response = await put(`/admin/tech-area/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteTechArea(id) {
  const response = await del(`/admin/tech-area/${id}`);
  return ResponseAdapter(response);
}
// Admin Connection Status
export async function getConnectionStatuses() {
  const response = await get('/admin/connection-status/');
  return ResponseAdapter(response);
}
export async function createConnectionStatus(data) {
  const response = await post('/admin/connection-status/', data);
  return ResponseAdapter(response);
}
export async function updateConnectionStatus(id, data) {
  const response = await put(`/admin/connection-status/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteConnectionStatus(id) {
  const response = await del(`/admin/connection-status/${id}`);
  return ResponseAdapter(response);
}
// Admin Business Models
export async function getBusinessModels() {
  const response = await get('/admin/business-model/');
  return ResponseAdapter(response);
}
export async function createBusinessModel(data) {
  const response = await post('/admin/business-model/', data);
  return ResponseAdapter(response);
}
export async function updateBusinessModel(id, data) {
  const response = await put(`/admin/business-model/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteBusinessModel(id) {
  const response = await del(`/admin/business-model/${id}`);
  return ResponseAdapter(response);
}
// Admin Industries
export async function getIndustries() {
  const response = await get('/admin/industry/');
  return ResponseAdapter(response);
}
export async function createIndustry(data) {
  const response = await post('/admin/industry/', data);
  return ResponseAdapter(response);
}
export async function updateIndustry(id, data) {
  const response = await put(`/admin/industry/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteIndustry(id) {
  const response = await del(`/admin/industry/${id}`);
  return ResponseAdapter(response);
}
// Admin Tags
export async function getTags() {
  const response = await get('/admin/tag/');
  return ResponseAdapter(response);
}
export async function createTag(data) {
  const response = await post('/admin/tag/', data);
  return ResponseAdapter(response);
}
export async function updateTag(id, data) {
  const response = await put(`/admin/tag/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteTag(id) {
  const response = await del(`/admin/tag/${id}`);
  return ResponseAdapter(response);
}
// Admin Users
export async function getUsers() {
  const response = await get('/admin/user/');
  return ResponseAdapter(response);
}
export async function deleteUser(id) {
  const response = await del(`/admin/user/${id}`);
  return ResponseAdapter(response);
}
export async function createUser(data) {
  const response = await post('/admin/user/', data);
  return ResponseAdapter(response);
}
export async function updateUser(id, data) {
  const response = await put(`/admin/user/${id}`, data);
  return ResponseAdapter(response);
}
export async function importData(data) {
  const response = await post('/admin/import-data/', data, null, false);
  return ResponseAdapter(response);
}
export async function postIndividualRegisteredEvents(data) {
  const response = await post('/admin/import-individual-registered-events/', data, null, false);
  return ResponseAdapter(response);
}
// COMPANY
export async function getCompanies(options = {}, filters = {}) {
  const { pageSize, page } = options;
  const response = await get(`/company/?filter=${stringify(filters)}&${toQueryString({ size: pageSize, page })}`);
  return ResponseAdapter(response);
}
export async function getCompanyNames() {
  const response = await get(`/company/names/`);
  return ResponseAdapter(response);
}
export async function getCompanyById(id) {
  const response = await get(`/company/${id}`);
  return ResponseAdapter(response);
}
export async function createCompany(data) {
  const response = await post('/company/', data);
  return ResponseAdapter(response);
}
export async function updateCompany(id, data) {
  const response = await put(`/company/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteCompany(id) {
  const response = await del(`/company/${id}`);
  return ResponseAdapter(response);
}
export async function getCompanyTypes() {
  const response = await get('/company/types/');
  return ResponseAdapter(response);
}
export async function createCompanyType(data) {
  const response = await post('/company/type/', data);
  return ResponseAdapter(response);
}
export async function updateCompanyType(id, data) {
  const response = await put(`/company/type/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteCompanyType(id) {
  const response = await del(`/company/type/${id}`);
  return ResponseAdapter(response);
}
export async function createCompanyConnection(data) {
  const response = await post('/company/connection/', data);
  return ResponseAdapter(response);
}
export async function updateCompanyConnection(data) {
  const response = await put('/company/connection/', data);
  return ResponseAdapter(response);
}
export async function getCompanyConnectionsById(id) {
  const response = await get(`/company/connection/${id}`);
  return ResponseAdapter(response);
}
export async function getCompanyConnections() {
  const response = await get(`/company/connections/`);
  return ResponseAdapter(response);
}
export async function deleteConnection(company1, company2, eventId) {
  const response = await del(`/company/connection/${company1}/${company2}/${eventId}`);
  return ResponseAdapter(response);
}
// Funders
export async function getFunders(options = {}, filters = {}) {
  const { pageSize, page } = options;
  const response = await get(`/funder/?filter=${stringify(filters)}&${toQueryString({ size: pageSize, page })}`);
  return ResponseAdapter(response);
}
export async function getFundingTypes() {
  const response = await get('/funder/funding-types');
  return ResponseAdapter(response);
}
export async function createFundingType(data) {
  const response = await post('/funder/funding-types/', data);
  return ResponseAdapter(response);
}
export async function updateFundingType(id, data) {
  const response = await put(`/funder/funding-types/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteFundingType(id) {
  const response = await del(`/funder/funding-types/${id}`);
  return ResponseAdapter(response);
}
export async function getFundingTicketSizes() {
  const response = await get('/funder/funding-ticket-sizes');
  return ResponseAdapter(response);
}
export async function getFundingStages() {
  const response = await get('/funder/funding-stages');
  return ResponseAdapter(response);
}
export async function getFundingRoles() {
  const response = await get('/funder/funding-roles');
  return ResponseAdapter(response);
}
export async function deleteFundingRole(id) {
  const response = await del(`/funder/funding-roles/${id}`);
  return ResponseAdapter(response);
}
export async function createFundingRole(data) {
  const response = await post('/funder/funding-roles/', data);
  return ResponseAdapter(response);
}
export async function updateFundingRole(id, data) {
  const response = await put(`/funder/funding-roles/${id}`, data);
  return ResponseAdapter(response);
}
// Countries
export async function getCountries() {
  const response = await get('/country/');
  return ResponseAdapter(response);
}
// Gdpr Options
export async function getGdprOptions() {
  const response = await get('/gdpr-option/');
  return ResponseAdapter(response);
}
export async function createGdprOption(data) {
  const response = await post('/gdpr-option/', data);
  return ResponseAdapter(response);
}
export async function updateGdprOption(id, data) {
  const response = await put(`/gdpr-option/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteGdprOption(id) {
  const response = await del(`/gdpr-option/${id}`);
  return ResponseAdapter(response);
}
// Events
export async function getEvents() {
  const response = await get('/event/');
  return ResponseAdapter(response);
}
export async function createEvent(data) {
  const response = await post('/event/', data);
  return ResponseAdapter(response);
}
export async function updateEvent(id, data) {
  const response = await put(`/event/${id}`, data);
  return ResponseAdapter(response);
}
export async function deleteEvent(id) {
  const response = await del(`/event/${id}`);
  return ResponseAdapter(response);
}
// Event Registrations
export async function getEventRegistrationByIndividual(id) {
  const response = await get(`/event-registration/individual/${id}`);
  return ResponseAdapter(response);
}
export async function registerIndividualToEvent(data) {
  const response = await post(`/event-registration/`, data);
  return ResponseAdapter(response);
}
export async function deleteEventRegistration(id) {
  const response = await del(`/event-registration/${id}`);
  return ResponseAdapter(response);
}
// Multi Match
export async function getMultiMatch(options, filters = {}) {
  const { pageSize, page, params } = options;
  const response = await get(`/multi-match/?filter=${JSON.stringify(filters)}&${toQueryString({ size: pageSize, page, params: JSON.stringify(params) })}`);
  return ResponseAdapter(response);
}
