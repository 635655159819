<template>
  <div class="min-h-screen">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" color="#f4f5fa" app mobile-breakpoint="720">
        <v-list dense class="left-side-menu">
          <template v-for="item in items">
            <v-list-group v-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.icon" append-icon="mdi-chevron-down">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.link">
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title :class="{ 'pl-14': !child.icon }">
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="item.text" link :to="item.link" @click="itemClickHandler(item)">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="green darken-4" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
          <span><img src="@/assets/empact-logo.png" style="height: 40px" /></span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-show="false">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
        <v-btn icon v-show="false">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main style="background-color: #f4f5fa">
        <div class="p-5">
          <transition name="fade-slide" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </v-main>
    </v-app>
    <SplashScreen v-if="showLoading" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SplashScreen from '@/components/ui/SplashScreen';

export default {
  name: 'SideLayout',
  components: { SplashScreen },
  data() {
    return {
      showLoading: true,
      drawer: null,
      items: [
        { icon: 'mdi-view-dashboard', text: 'Dashboard', link: '/' },
        {
          icon: 'mdi-account',
          text: 'Individuals',
          model: false,
          link: '/individual/list',
        },
        {
          icon: 'mdi-calendar',
          text: 'Events',
          model: false,
          link: '/event/list',
        },
        {
          icon: 'mdi-briefcase',
          text: 'Organisations',
          model: false,
          link: '/company/list',
        },
        {
          icon: 'mdi-account-cash',
          text: 'Funders',
          model: false,
          link: '/funder/list',
        },
        {
          icon: 'mdi-link',
          text: 'Connections',
          model: false,
          link: '/connections',
        },
        {
          icon: 'mdi-account-search-outline',
          text: 'Simple Match',
          model: false,
          link: '/simple-match',
        },
        // {
        //  icon: 'mdi-account-search-outline',
        //  text: 'Multi Match',
        //  model: false,
        //  link: '/multi-match',
        // },
        // {
        //   icon: 'mdi-briefcase-variant',
        //   text: 'Funders',
        //   model: false,
        //   children: [{ text: 'Funder List', link: '/funder/list' }],
        // },
        {
          icon: 'mdi-account-cog',
          text: 'Admin',
          model: false,
          children: [
            { text: 'Challenges', link: '/admin/challenges', model: false },
            { text: 'Outcomes', link: '/admin/outcomes', model: false },
            { text: 'Innovation Areas', link: '/admin/tech-areas', model: false },
            { text: 'Business Models', link: '/admin/business-models', model: false },
            { text: 'Industries', link: '/admin/industries', model: false },
            { text: 'Tags', link: '/admin/tags', model: false },
            { text: 'Company Types', link: '/admin/company-types', model: false },
            { text: 'Funding Types', link: '/admin/funding-types', model: false },
            { text: 'Funder Roles', link: '/admin/funder-roles', model: false },
            { text: 'Connection Status', link: '/admin/connection-status', model: false },
            { text: 'Import Data', link: '/admin/import-data', model: false },
            { text: 'Reg Ind. To Event (Bulk)', link: '/admin/import-individual-registered-events', model: false },
            { text: 'Users', link: '/admin/users', model: false },
            { text: 'GDPR Options', link: '/admin/gdpr-options', model: false },
          ],
        },
        { icon: 'mdi-logout', text: 'Logout', clickHandler: this.logout },
      ],
    };
  },
  created() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && !this.user) {
      this.loginWithToken(accessToken)
        .then(() => {
          this.bootstrap();
        })
        .catch(() => {
          this.$router.push('/login');
        });
    } else if (accessToken && this.user) {
      this.bootstrap();
    } else {
      this.$router.push('/login');
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions([
      'loginWithToken',
      'logout',
      'fetchCompanyTypes',
      'fetchCountries',
      'fetchOutcomes',
      'fetchChallenges',
      'fetchTechAreas',
      'fetchGdprOptions',
      'fetchFundingTypes',
      'fetchFundingTicketSizes',
      'fetchFundingStages',
      'fetchDashboardData',
      'fetchBusinessModels',
      'fetchIndustries',
      'fetchTags',
      'fetchFundingRoles',
      'fetchConnectionStatuses',
      'fetchEvents',
    ]),
    bootstrap() {
      this.fetchDashboardData();
      this.fetchCompanyTypes();
      this.fetchOutcomes();
      this.fetchChallenges();
      this.fetchTechAreas();
      this.fetchCountries();
      this.fetchGdprOptions();
      this.fetchFundingTypes();
      this.fetchFundingTicketSizes();
      this.fetchFundingStages();
      this.fetchBusinessModels();
      this.fetchIndustries();
      this.fetchTags();
      this.fetchFundingRoles();
      this.fetchConnectionStatuses();
      this.fetchEvents();
      this.showLoading = false;
    },
    itemClickHandler(item) {
      if (item.clickHandler) {
        item.clickHandler();
      }
    },
    selectActiveMenuItem() {
      this.items.forEach((item) => {
        if (item.link === this.$route.fullPath) {
          item.model = true;
        }

        if (item.children?.length) {
          if (item.children.some((child) => child.link === this.$route.fullPath)) {
            item.children.forEach((child) => {
              if (child.link === this.$route.fullPath) {
                child.model = true;
                item.model = true;
              }
            });
          } else {
            item.model = false;
          }
        }
      });
    },
  },
  watch: {
    '$route.path'() {
      this.selectActiveMenuItem();
    },
  },
};
</script>

<style lang="scss">
.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(0.5em);
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s ease;
}

#inspire {
  min-height: 100vh;
  overflow: hidden;
}
</style>
